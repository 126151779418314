export default {
    confirmationCode: {
        name: "confirmationCode",
        id: "confirmationCode",
        type: "number",
        required: true,
        value: "",
        label: "Kod z SMS",
        valid: false,
        minLength: 6,
        maxLength: 6,
    },
    password: {
        name: "password",
        id: "password",
        type: "password",
        required: true,
        value: "",
        label: "Nowe hasło",
        valid: false,
    },
    passwordConfirmation: {
        name: "passwordConfirmation",
        id: "passwordConfirmation",
        type: "password",
        required: true,
        value: "",
        label: "Powtórz nowe hasło",
        valid: false,
    },
};
