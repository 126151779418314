import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
    hideSideDrawer,
    showBottomNav,
    hideHeader,
    logIn,
    logOut,
} from "../state/actions";
import { useAuth } from "../hooks/redux";
import { navigate } from "gatsby";
import Layout from "../layout";
import SEO from "../components/seo";
import { Container, Row, Col, Alert } from "reactstrap";
import MyAccountHeader from "../components/molecules/MyAccountHeader/MyAccountHeader";
import userEditFormModel from "../formModels/userEditFormModel";
import updateFormField from "../utils/updateFormField";
import FormElement from "../components/atoms/FormElement/FormElement";
import Button from "../components/atoms/Button/Button";

const AccountEdit = ({ location }) => {
    const dispatch = useDispatch();
    const [auth] = useAuth();

    const [isLoading, setIsLoading] = useState(false);
    const [formMsg, setFormMsg] = useState({});
    const [userForm, setUserForm] = useState(userEditFormModel);
    const [avatar, setAvatar] = useState();
    const [avatarMsg, setAvatarMsg] = useState("");
    const [bg, setBg] = useState();
    const [bgMsg, setBgMsg] = useState("");

    useEffect(() => {
        const _userForm = { ...userForm };

        _userForm.username.value = auth.data.username;
        _userForm.phone.value = auth.data.phone;

        setUserForm(_userForm);

        dispatch(hideHeader());
        dispatch(showBottomNav());
        dispatch(hideSideDrawer());
    }, []);

    const isFormChanged = () => {
        return isUsernameChanged() || isPasswordChanged();
    };

    const isUsernameChanged = () => {
        let isNameChanged = false;

        if (
            userForm.username.value !== auth.data.username &&
            userForm.username.valid
        )
            isNameChanged = true;

        return isNameChanged;
    };

    const isPasswordChanged = () => {
        let isPasswordChanged = false;

        if (
            userForm.password.value &&
            userForm.passwordConfirmation.value &&
            !userForm.password.invalid
        )
            isPasswordChanged = true;

        return isPasswordChanged;
    };

    const isButtonActive = () => {
        let isActive = false;

        if (isFormChanged() || bg || avatar) isActive = true;

        return isActive;
    };

    const handleOnChange = (e) => {
        const { name, value } = e.target;

        setUserForm(updateFormField(name, value, userForm));
    };

    const handleOnBlur = (e) => {
        const { name } = e.target;
        const data = { ...userForm };

        if (data[name].invalid && data[name].msg) {
            data[name].invalidMsg = data[name].msg;
            setUserForm(data);
        }
    };

    const handleFileChange = (event) => {
        const { name, files } = event.target;
        const file = files[0];

        if (!file) return;

        const isValidSize = file.size / 1024 / 1024 < 2;
        const fileExtension = file.name
            .substr(file.name.lastIndexOf("."))
            .toLowerCase();
        const allowedExtension = [".png", ".jpg", ".jpeg"];
        const isValidExtension = allowedExtension.indexOf(fileExtension) !== -1;

        let msg = "";

        if (!isValidSize) {
            msg = "Zbyt duży rozmiar pliku. Maksymalny rozmiar pliku to 2MB";
        }

        if (!isValidExtension) {
            msg =
                "Niedozwolony typ pliku. Dozwolone typy to: " +
                allowedExtension.join(", ");
        }

        if (name === "file-1") {
            if (msg) {
                setAvatarMsg(msg);
                setAvatar(null);
                clearFileField("file-1");
            } else {
                setAvatarMsg("");
                setAvatar(file);
            }
        }

        if (name === "file-2") {
            if (msg) {
                setBgMsg(msg);
                setBg(null);
                clearFileField("file-2");
            } else {
                setBgMsg("");
                setBg(file);
            }
        }
    };

    const clearFileField = (inputId) => {
        document.getElementById(inputId).value = null;
    };

    const onSubmit = async () => {
        setFormMsg({});

        let dataToSend = {};

        if (isUsernameChanged()) {
            dataToSend.username = userForm.username.value;
        }

        if (isPasswordChanged()) {
            dataToSend.password = userForm.password.value;
        }

        setIsLoading(true);

        if (avatar) {
            const imageId = await uploadFile(avatar);

            clearFileField("file-1");

            if (imageId) {
                dataToSend.avatar = imageId;
            }
        }

        if (bg) {
            const imageId = await uploadFile(bg);

            clearFileField("file-2");

            if (imageId) {
                dataToSend.bgimage = imageId;
            }
        }

        if (Object.keys(dataToSend).length > 0) {
            try {
                const response = await axios.put(
                    `${process.env.STRAPI_API_URL}/users/${auth.data.id}`,
                    dataToSend,
                    {
                        headers: {
                            Authorization: `Bearer ${auth.data.jwt}`,
                        },
                    }
                );

                const formInitData = { ...userEditFormModel };

                formInitData.username.value = response.data.username;
                formInitData.phone.value = response.data.phone;

                setUserForm(formInitData);
                dispatch(logIn({ jwt: auth.data.jwt, user: response.data }));
                setFormMsg({
                    style: "success",
                    value: "Zmiany zostały zapisane.",
                });
                setIsLoading(false);
                setBg(null);
                setAvatar(null);
                if (document) {
                    document.body.scrollTop =
                        document.documentElement.scrollTop = 0;
                }
            } catch (error) {
                const { status } = error.response;

                setIsLoading(false);
                setBg(null);
                setAvatar(null);
                setFormMsg({
                    style: "danger",
                    value: "Wystąpił błąd i nie udało się zapisać zmian. Prosimy spróbować później.",
                });
                if (document) {
                    document.body.scrollTop =
                        document.documentElement.scrollTop = 0;
                }

                if (status === 401 || status === 403 || status === 405) {
                    dispatch(logOut());
                    navigate("/app/logowanie");
                }
            }
        } else {
            setIsLoading(false);
        }
    };

    const uploadFile = async (file) => {
        let form = new FormData();

        form.append("files", file);

        try {
            const response = await axios.post(
                `${process.env.STRAPI_API_URL}/upload`,
                form,
                {
                    headers: {
                        Authorization: `Bearer ${auth.data.jwt}`,
                    },
                }
            );

            return response.data[0].id;
        } catch (error) {
            return null;
        }
    };

    return (
        <Layout>
            <SEO title="Moje konto" />

            <MyAccountHeader
                bg={bg ? URL.createObjectURL(bg) : ""}
                avatar={avatar ? URL.createObjectURL(avatar) : ""}
            />

            <Container className="my-account my-account--edit-form">
                <Row>
                    <Col lg={{ size: 10, offset: 1 }}>
                        {formMsg.value && (
                            <Alert color={formMsg.style}>{formMsg.value}</Alert>
                        )}

                        <div className="my-account--heading">
                            Informacje ogólne
                        </div>

                        <FormElement
                            {...userForm.username}
                            onChange={handleOnChange}
                            onBlur={handleOnBlur}
                            valid={
                                isUsernameChanged()
                                    ? userForm.username.valid
                                    : false
                            }
                        />

                        <FormElement {...userForm.phone} disabled={true} />

                        <div className="my-account--heading">Zmiana hasła</div>
                        <FormElement
                            {...userForm.password}
                            onChange={handleOnChange}
                            onBlur={handleOnBlur}
                            valid={
                                isPasswordChanged()
                                    ? userForm.password.valid
                                    : false
                            }
                        />
                        <FormElement
                            {...userForm.passwordConfirmation}
                            onChange={handleOnChange}
                            onBlur={handleOnBlur}
                            valid={
                                isPasswordChanged()
                                    ? userForm.passwordConfirmation.valid
                                    : false
                            }
                        />

                        <div className="my-account--heading">
                            Grafiki profilu
                        </div>

                        <Row>
                            <Col lg="6">
                                <Button
                                    onClick={() => {
                                        if (avatar) {
                                            setAvatar(null);
                                            clearFileField("file-1");
                                        } else {
                                            document
                                                .getElementById("file-1")
                                                .click();
                                        }
                                    }}
                                    className="btn--primary w-100 btn--md btn--primary__outline mb-4 mb-lg-0"
                                >
                                    {avatar
                                        ? "Usuń wybrane zdjęcie"
                                        : "Zmień zdjęcie profilowe"}
                                </Button>

                                {avatarMsg && (
                                    <div className="mt-3 text-danger">
                                        {avatarMsg}
                                    </div>
                                )}
                            </Col>

                            <Col lg="6">
                                <Button
                                    onClick={() => {
                                        if (bg) {
                                            setBg(null);
                                            clearFileField("file-2");
                                        } else {
                                            document
                                                .getElementById("file-2")
                                                .click();
                                        }
                                    }}
                                    className="btn--primary w-100 btn--md btn--primary__outline"
                                >
                                    {bg
                                        ? "Usuń wybraną grafikę"
                                        : "Zmień grafikę w tle"}
                                </Button>

                                {bgMsg && (
                                    <div className="mt-3 text-danger">
                                        {bgMsg}
                                    </div>
                                )}
                            </Col>
                            <Col>
                                <hr className="mt-5 mb-0" />
                            </Col>
                        </Row>

                        <Button
                            className={`btn--primary w-100 btn--md mt-5 mb-5 ${
                                isLoading ? "btn--loading" : ""
                            }`}
                            onClick={onSubmit}
                            disabled={isLoading || !isButtonActive()}
                        >
                            Zapisz zmiany
                        </Button>
                    </Col>
                </Row>
            </Container>

            <input
                type="file"
                name="file-1"
                id="file-1"
                className="d-none"
                accept="image/*"
                onChange={handleFileChange}
            />

            <input
                type="file"
                name="file-2"
                id="file-2"
                className="d-none"
                accept="image/*"
                onChange={handleFileChange}
            />
        </Layout>
    );
};

export default AccountEdit;
