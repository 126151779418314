import React from "react";
import Link from "../../atoms/Link/Link";

import Icon from "../../atoms/Icon/Icon";

import "./myAccountRouteBox.scss";

const MyAccountRouteBox = ({
    id,
    progress,
    name,
    locations,
    distance,
    places,
}) => {
    const placesCount = places.length;
    let placesCountLabel = "";
    let locationString = "";
    const circumference = 6 * 2 * Math.PI;
    const offset = circumference - (progress / 100) * circumference;

    locations.forEach((_) => (locationString += _.name + " "));

    if (placesCount) {
        placesCountLabel = "wyzwań";
        const placesCountLastDigit = placesCount.toString().split("").pop();

        if (placesCount === 1) placesCountLabel = "wyzwanie";
        if (placesCount === 2 || placesCount === 3 || placesCount === 4)
            placesCountLabel = "wyzwania";
        if (
            placesCountLastDigit > 1 &&
            placesCountLastDigit < 5 &&
            placesCount > 20
        )
            placesCountLabel = "wyzwania";
    }

    return (
        <Link className="my-account-routes--route" to={`/trasy/${id}`}>
            <div className="my-account-routes--route-name">
                <div>
                    {progress ? (
                        <div className="my-account-routes--route-progress">
                            <svg height="16" width="16">
                                <circle
                                    cx="8"
                                    cy="8"
                                    r="6"
                                    stroke="#1f8636"
                                    strokeWidth="4"
                                    fill="transparent"
                                    style={{
                                        strokeDashoffset: offset,
                                        strokeDasharray: `${circumference} ${circumference}`,
                                    }}
                                />
                            </svg>
                        </div>
                    ) : (
                        <Icon className="icon-heart-filled-min" />
                    )}
                    {name}
                </div>
                <Icon className="icon-chevron-left-min" />
            </div>
            <div className="my-account-routes--route-meta">
                <div>
                    <Icon className="icon-pin-min" /> {locationString}
                </div>
                <div>
                    <Icon className="icon-distance-min" />{" "}
                    {(distance / 1000).toFixed(1).replace(".0", "")} km
                </div>
                <div>
                    <Icon className="icon-bulb-min" /> {placesCount}{" "}
                    {placesCountLabel}
                </div>
            </div>
        </Link>
    );
};

export default MyAccountRouteBox;
