export default {
    phone: {
        name: "phone",
        id: "phone",
        type: "tel",
        required: true,
        value: "",
        label: "Numer telefonu",
        valid: false,
    },
};
