export default {
    phone: {
        name: "phone",
        id: "phone",
        type: "tel",
        required: true,
        value: "",
        label: "Numer telefonu",
        valid: false,
    },
    password: {
        name: "password",
        id: "password",
        type: "password",
        required: true,
        value: "",
        label: "Hasło",
        valid: false,
    },
};
