export default {
    username: {
        name: "username",
        id: "username",
        type: "text",
        required: true,
        value: "",
        label: "Nazwa użytkownika",
        valid: false,
        minLength: 3,
    },
    phone: {
        name: "phone",
        id: "phone",
        type: "number",
        required: true,
        value: "",
        label: "Numer telefonu",
        valid: false,
    },
    password: {
        name: "password",
        id: "password",
        type: "password",
        required: false,
        value: "",
        label: "Hasło",
        valid: false,
    },
    passwordConfirmation: {
        name: "passwordConfirmation",
        id: "passwordConfirmation",
        type: "password",
        required: false,
        value: "",
        label: "Powtórz hasło",
        valid: false,
    },
};
