import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { navigate } from "gatsby";
import { Container, Row, Col } from "reactstrap";

// RECAPTCHA
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

// ACTIONS
import {
  logIn,
  hideHeader,
  hideBottomNav,
  hideSideDrawer,
} from "../state/actions";

// COMPONENTS
import Layout from "../layout";
import SEO from "../components/seo";
import Heading from "../components/atoms/Heading/Heading";
import AuthTabNav from "../components/molecules/AuthTabNav/AuthTabNav";
import LoginForm from "../components/organisms/LoginForm/LoginForm";
import RegisterConfirmModal from "../components/organisms/RegisterConfirmModal/RegisterConfirmModal";
import Icon from "../components/atoms/Icon/Icon";
import Button from "../components/atoms/Button/Button";

// MODELS
import loginFormModel from "../formModels/loginFormModel";
import registrationConfirmationFormModel from "../formModels/registrationConfirmationFormModel";

// UTILS
import updateFormField from "../utils/updateFormField";
import validateForm from "../utils/validateForm";

const Login = ({ location }) => {
  const dispatch = useDispatch();
  const [loginForm, setLoginForm] = useState(loginFormModel);
  const [isLoading, setIsLoading] = useState(false);
  const [formErrorMsg, setFormErrorMsg] = useState("");
  const [isConfirm, setIsConfirm] = useState(false);
  const [confirmationErrorMsg, setConfirmationErrorMsg] = useState("");
  const [confirmForm, setConfirmForm] = useState(
    registrationConfirmationFormModel
  );
  const [userId, setUserId] = useState("");

  // INITIALIZE RECAPTCHA
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    dispatch(hideHeader());
    dispatch(hideBottomNav());
    dispatch(hideSideDrawer());
  }, []);

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    isConfirm
      ? setConfirmForm(updateFormField(name, value, confirmForm))
      : setLoginForm(updateFormField(name, value, loginForm));
  };

  const handleOnBlur = (e) => {
    const { name } = e.target;
    const data = isConfirm ? { ...confirmForm } : { ...loginForm };

    if (data[name].invalid && data[name].msg) {
      data[name].invalidMsg = data[name].msg;
      isConfirm ? setConfirmForm(data) : setLoginForm(data);
    }
  };

  const sendForm = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    // Check if the captcha was skipped or not
    if (!executeRecaptcha) {
      setIsLoading(false);
      return;
    }

    // GET RECAPTCHA TOKEN
    const token = await executeRecaptcha();

    axios
      .post(`${process.env.STRAPI_API_URL}/auth/local`, {
        identifier: loginForm.phone.value,
        password: loginForm.password.value,
        token,
      })
      .then((res) => {
        setLoginForm(loginFormModel);
        setFormErrorMsg(false);
        setIsLoading(false);
        dispatch(logIn(res.data));
        if (location?.state?.redirectUrl) {
          navigate(location?.state?.redirectUrl);
        } else {
          navigate("/app/moje-konto");
        }
      })
      .catch((err) => {
        if (
          err.response &&
          JSON.stringify(err.response.data).indexOf(
            "Auth.form.error.confirmed"
          ) !== -1
        ) {
          setIsConfirm(true);
          setUserId(err.response.data.message.userId);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setFormErrorMsg("Logowanie nie powiodło się.");
        }
      });
  };

  const sendConfirmationCode = (e) => {
    e.preventDefault();

    setIsLoading(true);

    axios
      .get(
        `${process.env.STRAPI_API_URL}/auth/phone-confirmation?confirmation=${confirmForm.confirmationCode.value}&userid=${userId}`
      )
      .then((res) => {
        setIsLoading(false);
        setLoginForm(loginFormModel);
        setConfirmationErrorMsg("");
        dispatch(logIn(res.data));
        navigate("/app/moje-konto", { state: { firstLogin: true } });
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setConfirmationErrorMsg(
          "Nie udało się potwierdzić rejestracji. Upewnij się, że wpisany kod jest poprawny i spróbuj ponownie."
        );
      });
  };

  return (
    <Layout>
      <SEO title="Logowanie" />

      <Container>
        <Row>
          <Col>
            <Button
              className="btn--circle btn--back"
              onClick={() => navigate("/trasy")}
              aria-label="Cofnij do poprzedniego widoku"
            >
              <Icon className="icon-chevron-left-min" />
            </Button>
          </Col>
        </Row>
      </Container>

      <Container className="auth-wrapper">
        <Row>
          <Col lg={{ size: 10, offset: 1 }}>
            <AuthTabNav
              redirectUrl={location?.state?.redirectUrl}
            />

            <Heading as="h1">Witaj ponownie!</Heading>

            <LoginForm
              formData={loginForm}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              onSubmit={sendForm}
              isLoading={isLoading}
              isFormValid={validateForm}
              formErrorMsg={formErrorMsg}
            />

            <RegisterConfirmModal
              modal={isConfirm}
              setModal={setIsConfirm}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              onSubmit={sendConfirmationCode}
              formData={confirmForm}
              confirmationErrorMsg={confirmationErrorMsg}
              isLoading={isLoading}
              isFormValid={validateForm}
              userId={userId}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Login;
