import React from "react";
import { navigate } from "gatsby-link";

import { useAuth } from "../../../hooks/redux";

import { Col, Container, Row } from "reactstrap";
import Button from "../../atoms/Button/Button";
import Icon from "../../atoms/Icon/Icon";

import UserPlaceholder from "../../../assets/images/user-placeholder.png";
// STYLE
import "./myAccountHeader.scss";

const MyAccountHeader = ({ backUrl, avatar, bg }) => {
    const [auth] = useAuth();

    return (
        <div className="my-account-header">
            <div
                className="my-account-header--bg"
                style={{
                    backgroundImage: `url(${bg || auth.data.bgimage})`,
                }}
            >
                <Container>
                    <Row>
                        <Col>
                            <Button
                                className="btn--circle btn--back"
                                onClick={() =>
                                    navigate(backUrl || "/app/moje-konto")
                                }
                                aria-label="Cofnij do poprzedniego widoku"
                            >
                                <Icon className="icon-chevron-left-min" />
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row>
                    <Col>
                        <div className="my-account-header--details">
                            <div
                                className="my-account-header--avatar"
                                style={{
                                    backgroundImage: `url(${avatar ||
                                        auth.data.avatar ||
                                        UserPlaceholder
                                        })`,
                                }}
                            ></div>
                            <div className="my-account-header--name">
                                {auth.data.username}
                            </div>
                            <div className="my-account-header--phone">
                                {auth.data.phone}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default MyAccountHeader;
