import React, { useState } from "react";
import axios from "axios";

// STYLE
import "./registerConfirmModal.scss";

// COMPONENTS
import { Alert } from "reactstrap";
import Modal from "../../molecules/Modal/Modal";
import Button from "../../atoms/Button/Button";
import Paragraph from "../../atoms/Paragraph/Paragraph";
import FormElement from "../../atoms/FormElement/FormElement";
import Heading from "../../atoms/Heading/Heading";

const RegisterConfirmModal = ({
    modal,
    setModal,
    handleOnChange,
    handleOnBlur,
    onSubmit,
    formData,
    confirmationErrorMsg,
    isLoading,
    isFormValid,
    userId,
}) => {
    const [loadingSendSms, setLoadingSendSms] = useState(false);
    const [sendSmsErrorMsg, setSendSmsErrorMsg] = useState("");

    const sendSms = () => {
        setLoadingSendSms(true);
        setSendSmsErrorMsg("");

        axios
            .get(
                `${process.env.STRAPI_API_URL}/users/me/send-confirm-code?userid=${userId}`
            )
            .then((res) => {
                setLoadingSendSms(false);
            })
            .catch((err) => {
                console.log(err);
                setLoadingSendSms(false);
                setSendSmsErrorMsg(
                    "Nie udało się wysłać wiadomości SMS. Jeśli problem będzie się powtarzał, skontaktuj się z obsługą aplikacji."
                );
            });
    };

    return (
        <Modal
            className="modal--sm register-confirm-modal"
            modal={modal}
            setModal={() => setModal(false)}
            BottomActions={() => (
                <>
                    <Button
                        className={`btn--primary btn--md btn-block ${isLoading ? "btn--loading" : ""
                            }`}
                        disabled={isLoading || !isFormValid(formData)}
                        onClick={(e) => {
                            setSendSmsErrorMsg("");
                            onSubmit(e);
                        }}
                    >
                        Dalej
                    </Button>
                    <Button
                        className={`btn--primary__outline btn--md btn-block mt-4`}
                        disabled={loadingSendSms}
                        onClick={sendSms}
                    >
                        Wyślij kod ponownie
                    </Button>
                </>
            )}
        >
            <Heading as="h1">Weryfikacja kodu</Heading>
            <Paragraph className="register-confirm-modal--desc">
                Na podany numer została wysłana wiadomość SMS z kodem
                weryfikacyjnym. Proszę wprowadzić go poniżej.
            </Paragraph>

            {confirmationErrorMsg && !sendSmsErrorMsg && (
                <Alert color="danger">{confirmationErrorMsg}</Alert>
            )}

            {sendSmsErrorMsg && <Alert color="danger">{sendSmsErrorMsg}</Alert>}

            <div className="register-confirm-modal--input-wrapper">
                <FormElement
                    {...formData.confirmationCode}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    className="form--input register-confirm-modal--input"
                />
            </div>
        </Modal>
    );
};

export default RegisterConfirmModal;
