export default {
    username: {
        name: "username",
        id: "username",
        type: "text",
        required: true,
        value: "",
        label: "Nazwa użytkownika",
        valid: false,
        minLength: 3,
    },
    phone: {
        name: "phone",
        id: "phone",
        type: "tel",
        required: true,
        value: "",
        label: "Numer telefonu",
        valid: false,
    },
    password: {
        name: "password",
        id: "password",
        type: "password",
        required: true,
        value: "",
        label: "Hasło",
        valid: false,
    },
    passwordConfirmation: {
        name: "passwordConfirmation",
        id: "passwordConfirmation",
        type: "password",
        required: true,
        value: "",
        label: "Powtórz hasło",
        valid: false,
    },
    acceptance1: {
        name: "acceptance1",
        id: "acceptance1",
        type: "checkbox",
        required: true,
        value: false,
        valid: false,
        label: `Oświadczam, że zapoznałem się z zapisami w <a href="/regulamin" target="_blank">regulaminie</a> serwisu`,
    },
    acceptance2: {
        name: "acceptance2",
        id: "acceptance2",
        type: "checkbox",
        required: true,
        value: false,
        valid: false,
        label: `Oświadczam, że zapoznałem się z zapisami w <a href="/polityka-prywatnosci" target="_blank">polityce prywatności</a> serwisu`,
    },
};
