import React from "react";

// STYLE
import "./registerForm.scss";

// COMPONENTS
import { Form, Alert } from "reactstrap";

import FormElement from "../../atoms/FormElement/FormElement";
import Button from "../../atoms/Button/Button";
import PlainText from "../../atoms/PlainText/PlainText";

const RegisterForm = ({
    formData,
    handleOnChange,
    handleOnBlur,
    onSubmit,
    isFormValid,
    formErrorMsg,
    isLoading,
}) => (
    <>
        <PlainText className="register-form--info">
            Dzięki założeniu konta możesz: dodawać trasy do ulubionych,
            zapisywać wyniki z ukończonych tras, zdobywać punkty i poziomy
            odkrywcy.
        </PlainText>

        {formErrorMsg && <Alert color="danger">{formErrorMsg}</Alert>}

        <Form className="register-form" onSubmit={onSubmit}>
            <FormElement
                {...formData.username}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
            />
            <FormElement
                {...formData.phone}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
            />

            <PlainText className="register-form--info">
                Numer telefonu nie będzie używany w celach marketingowych. Jest
                potrzebny jedynie w celu weryfikacji zakładanego konta w
                aplikacji.
            </PlainText>

            <FormElement
                {...formData.password}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
            />
            <FormElement
                {...formData.passwordConfirmation}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
            />
            <FormElement {...formData.acceptance1} onChange={handleOnChange} />
            <FormElement {...formData.acceptance2} onChange={handleOnChange} />

            <Button
                className={`btn--primary btn--md ${isLoading ? "btn--loading" : ""
                    }`}
                disabled={isLoading || !isFormValid(formData)}
                type="submit"
            >
                Stwórz moje konto
            </Button>
        </Form>
    </>
);

export default RegisterForm;
