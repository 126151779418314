import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { hideSideDrawer, showBottomNav, hideHeader } from "../state/actions";

import { useAuth, useRanksModal } from "../hooks/redux";
import * as Strapi from "../hooks/strapi";

import { Container, Row, Col } from "reactstrap";
import Layout from "../layout";
import SEO from "../components/seo";

import MyAccountHeader from "../components/molecules/MyAccountHeader/MyAccountHeader";
import Icon from "../components/atoms/Icon/Icon";
import Button from "../components/atoms/Button/Button";

const MyAchievements = () => {
    const dispatch = useDispatch();
    const [, { showRanksModal }] = useRanksModal();
    const [auth] = useAuth();
    const global = Strapi.useGlobal();

    const maxPoints = global.rank[global.rank.length - 1].points_to || 100;

    let userPoints = auth.data.stats.points || 0;
    if (userPoints > maxPoints) userPoints = maxPoints;

    const userDistance = auth.data.stats.distance || 0;
    const userRank = global.rank
        .filter(
            (rank) =>
                userPoints >= rank.points_from && userPoints <= rank.points_to
        )
        .pop();

    useEffect(() => {
        dispatch(hideHeader());
        dispatch(showBottomNav());
        dispatch(hideSideDrawer());
    }, []);

    const circleSize = 94;
    const circleStrokeWidth = 10;

    const circleRadius = (circleSize - circleStrokeWidth) / 2;
    const circumference = circleRadius * 2 * Math.PI;
    const offset = circumference - (userPoints / maxPoints) * circumference;
    const pointsCircle = (
        <svg height={circleSize} width={circleSize}>
            <circle
                cx={circleSize / 2}
                cy={circleSize / 2}
                r={circleRadius}
                stroke="#1f8636"
                strokeWidth="10"
                fill="transparent"
                style={{
                    strokeDashoffset: offset,
                    strokeDasharray: `${circumference} ${circumference}`,
                }}
            />
        </svg>
    );

    const pointsIndicator = (
        <svg width="16" height="13.176" viewBox="0 0 16 13.176">
            <path d="M6.29,2.816a2,2,0,0,1,3.419,0l4.446,7.323a2,2,0,0,1-1.71,3.038H3.554a2,2,0,0,1-1.71-3.038Z" />
        </svg>
    );

    return (
        <Layout>
            <SEO title="Moje konto" />

            <MyAccountHeader />

            <Container className="my-account">
                <Row>
                    <Col lg={{ size: 10, offset: 1 }}>
                        <div className="my-account--heading">
                            Łączna liczba Twoich punktów
                            <Button
                                className="btn--circle btn--back"
                                onClick={() => showRanksModal()}
                                aria-label="Pokaż informacje o rangach"
                            >
                                <Icon className="icon-info-min" />
                            </Button>
                        </div>
                        <div className="my-account--rank">
                            <div className="my-account--rank-name">
                                {userRank.name}
                            </div>
                            <div className="my-account--rank-scale">
                                <div
                                    className="my-account--rank-scale-fill"
                                    style={{
                                        width:
                                            (userPoints / maxPoints) * 100 +
                                            "%",
                                    }}
                                ></div>
                                <div className="my-account--rank-scale-points">
                                    {global.rank.map((rank) => (
                                        <div
                                            key={rank.id}
                                            className={`${userPoints >= rank.points_from
                                                    ? "active"
                                                    : ""
                                                }`}
                                        >
                                            {pointsIndicator}{" "}
                                            <span>{rank.points_from} pkt</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="my-account--heading">
                            Łączna liczba Twoich punktów
                        </div>
                        <div className="my-account--rank-circle">
                            {pointsCircle}
                            {userPoints} pkt
                        </div>

                        <div className="my-account--heading">
                            Twoje osiągnięcia
                        </div>
                        <div className="my-account--stats">
                            <div>
                                <Icon className="icon-distance-min" />
                                <span>
                                    {(userDistance / 1000)
                                        .toFixed(1)
                                        .replace(".0", "")}{" "}
                                    km
                                </span>
                            </div>
                            <div>
                                <Icon className="icon-bulb-min" />
                                <span>
                                    {auth.data.stats.type_2 || 0} wyzwania
                                </span>
                            </div>
                            <div>
                                <Icon className="icon-storytelling-min" />
                                <span>
                                    {auth.data.stats.type_1 || 0} historii
                                </span>
                            </div>
                            <div>
                                <Icon className="icon-heart-rate-min" />
                                <span>
                                    {auth.data.stats.type_3 || 0} ćwiczenia
                                </span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
};

export default MyAchievements;
